<template>
  <custom-modal ref="newPaymentMethod" extra-class="bg-sky-300 text-white/90 font-medium fill-white/90"
    width-class="w-screen" container-class="w-5/6 md:w-3/4 lg:w-2/3 xl:w-1/2">
    <template v-slot:header>
      {{ $t('customerDashboard.add_method') }}
    </template>
    <template v-slot:body>
      <div>
        <stripe-payment-method-widget :customer="user.customer" @close="closePaymentMethod()"></stripe-payment-method-widget>
      </div>
    </template>
  </custom-modal>

  <custom-modal ref="buyHarp" extra-class="bg-sky-300 text-white/90 font-medium fill-white/90" width-class="w-screen"
    container-class="w-5/6 md:w-3/4 lg:w-2/3 xl:w-1/2">
    <template v-slot:header>
      {{ $t('customerDashboard.buy_harp') }}
    </template>
    <template v-slot:body>
      <div class="w-full flex flex-col items-center">
        <h4 class="text-xl text-center font-medium text-red-600 mb-2">{{ $t('customerDashboard.attention_buy') }}
          <a href="mailto:noleggi@salvimusic.it" class="text-sky-400 underline"> {{ $t('email') }}</a>
        </h4>
        <p class="w-full md:w-4/5 md:text-xl text-center font-thin">{{ $t('customerDashboard.confirm_buy') }}</p>
        <div class="flex space-x-3 mt-3">
          <base-button @click="buyHarp()" extra-class="border-sky-400/90 text-sky-400/90 hover:bg-sky-400/90">
            {{ $t('customerDashboard.yes') }}
          </base-button>
          <base-button @click="$refs.buyHarp.closeModal()"
            extra-class="border-sky-400/90 text-sky-400/90 hover:bg-sky-400/90">
            {{ $t('customerDashboard.no') }}
          </base-button>
        </div>
      </div>
    </template>
  </custom-modal>

  <custom-modal ref="giveHarpBack" extra-class="bg-sky-300 text-white/90 font-medium fill-white/90" width-class="w-screen"
    container-class="w-5/6 md:w-3/4 lg:w-2/3 xl:w-1/2">
    <template v-slot:header>
      {{ $t('customerDashboard.return_harp') }}
    </template>
    <template v-slot:body>
      <div class="w-full flex flex-col items-center">
        <p class="w-full md:w-4/5 md:text-xl text-center font-thin">{{ $t('customerDashboard.confirm_return') }}</p>
        <div class="flex space-x-3 mt-3">
          <base-button @click="giveHarpBack()" extra-class="border-sky-400/90 text-sky-400/90 hover:bg-sky-400/90">
            {{ $t('customerDashboard.yes') }}
          </base-button>
          <base-button @click="$refs.giveHarpBack.closeModal()"
            extra-class="border-sky-400/90 text-sky-400/90 hover:bg-sky-400/90">
            {{ $t('customerDashboard.no') }}
          </base-button>
        </div>
      </div>
    </template>
  </custom-modal>

  <div class="py-14 bg-gradient-to-br from-sky-300 to-sky-800 min-h-screen flex justify-center">
    <div
      class="w-5/6 2xl:w-3/4 rounded-lg p-6 md:p-10 shadow-2xl bg-white dark:bg-slate-600 dark:text-white flex flex-col">
      <div class="w-full flex justify-center mb-4">
        <img src="../assets/logo-salvi-music.png" alt="logo Salvi Music" class="w-56 block dark:hidden">
        <img src="../assets/logo-salvi-music-white.png" alt="logo Salvi Music" class="w-56 hidden dark:block">
      </div>
      <top-navbar></top-navbar>
      <section class="flex flex-col w-full font-light">
        <div class="w-full xl:w-11/12 mx-auto whitespace-pre-line self-start flex flex-col mb-6">
          <p class="text-2xl font-bold self-start capitalize">{{ $t('customerDashboard.dear') }} {{ user ? user.name : '' }},</p>
          <p>{{ $t('customerDashboard.your_dashboard') }}</p>
        </div>
        <div class="flex flex-col xl:flex-row space-y-10 xl:space-y-0 xl:space-x-10">
          <div class="xl:w-1/2">
            <h3 class="text-xl font-medium">{{ $t('customerDashboard.payment_methods') }}</h3>
            <section>
              <div class="mt-3 flex flex-col" v-if="paymentMethods.length > 0">
                <div v-for="method in paymentMethods" :key="method.id"
                  class="py-3 h-16 flex justify-between items-center border-b">
                  <card-info :method="method" @deleted="getPaymentMethods()" />
                </div>
              </div>
              <div v-else>
                {{ $t('customerDashboard.no_payment_methods') }}
              </div>
            </section>

            <base-button class="mt-4" @click="$refs.newPaymentMethod.openModal()"
              extra-class="border-sky-400/90 text-sky-400/90 hover:bg-sky-400/90">
              {{ $t('customerDashboard.add_method') }}
            </base-button>
          </div>
          <div class="xl:w-1/2">
            <h3 class="text-xl font-medium">{{ $t('customerDashboard.your_rental') }}</h3>
            <div class="w-full justify-between text-right p-3 border
              rounded-md shadow-md items-center mt-3 space-y-2"
              v-for="contract in contracts"
              :key="contract.id">
              <div class="w-full flex flex-col space-y-2">
                <div class="flex flex-col text-center md:text-left space-y-1 text-sm">
                  <p class="font-semibold text-base">{{ $t('customerDashboard.contract') }} {{ contract.contract_no }}</p>
                  <p>{{ $t('customerDashboard.start_date') }} {{ $d(contract.created_at, 'short') }}</p>
                </div>
                <div class="flex flex-col space-y-1 text-sm">
                  <a :href="contract.pdf_url" target="_blank" v-if="contract.pdf_url" class="w-fit">
                    <base-button
                      extra-class="border-sky-400/90 text-sky-400/90 hover:bg-sky-400/90 text-sm"
                    >
                      {{ $t('contracts.showContract') }}
                    </base-button>
                  </a>
                  <base-button
                    @click="openBuyHarp(contract)"
                    v-if="showButtons(contract.created_at, contract.id)"
                    :disabled="disableButton(contract)"
                    extra-class="mt-2 border-sky-400/90 text-sky-400/90 hover:bg-sky-400/90 disabled:border-gray-600
                    disabled:text-gray-600 hover:disabled:text-gray-600 disabled:bg-gray-200 disabled:cursor-not-allowed
                    disabled:opacity-40"
                  >
                    {{ $t('customerDashboard.buy_harp') }}
                  </base-button>
                  <base-button
                    @click="openGiveHarpBack(contract)"
                    v-if="showButtons(contract.created_at, contract.id)"
                    :disabled="disableButton(contract)"
                    extra-class="border-sky-400/90 text-sky-400/90 hover:bg-sky-400/90 disabled:border-gray-600
                    disabled:text-gray-600 hover:disabled:text-gray-600 disabled:bg-gray-200 disabled:cursor-not-allowed
                    disabled:opacity-40"
                  >
                    {{ $t('customerDashboard.return_harp') }}
                  </base-button>
                </div>
              </div>

              <div  class="w-full">
                <div class="mt-8 flow-root">
                  <div class="flex flex-row items-center">
                    <h3 class="text-left text-xl font-medium">{{ $t('customerDashboard.your_payments') }}</h3>
                    <p class="text-left text-xs ml-4">{{ $t('customerDashboard.nextPaymentDate') }}: <strong>{{ nextPaymentDate(contract.id) }}</strong></p>
                  </div>

                  <payment-table :contract="contract" :contract-invoices="contractInvoices" :is-loading="isLoading" />

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import workflowStates from '@/config/customer-buttons-states';
import CustomModal from '@/components/base/CustomModal.vue';
import dayjs from 'dayjs';
import BaseButton from '../components/base/BaseButton.vue';
import TopNavbar from '../components/base/TopNavbar.vue';
import CardInfo from '../components/graphics/CardInfo.vue';
import StripePaymentMethodWidget from '../components/customers/StripePaymentMethodWidget.vue';
import PaymentTable from '../components/contracts/PaymentTable.vue';

export default {
  components: {
    BaseButton,
    CustomModal,
    CardInfo,
    StripePaymentMethodWidget,
    TopNavbar,
    PaymentTable,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      paymentMethods: [],
      contracts: [],
      workflowStates,
      contractInvoices: [],
      isLoading: false,
    };
  },
  async created() {
    this.customerPreferredLanguage();
    await this.initData();
  },
  computed: {
    ...mapGetters('customers', ['customer']),
    ...mapGetters('contracts', ['contract']),
    ...mapGetters('auth', ['user']),
  },
  methods: {
    customerPreferredLanguage() {
      this.$i18n.locale = this.user.customer.lang || 'it';
    },
    ...mapActions('auth', ['signOut']),
    ...mapActions('customers', [
      'getPaymentMethods',
      'getCustomerByHash',
      'setCustomer',
      'getContractsByCustomer',
      'buyGood',
      'sendBackGood',
    ]),
    ...mapActions('contracts', [
      'setContract',
      'getInvoices',
    ]),
    async initData() {
      this.paymentMethods = await this.getPaymentMethods(this.user.customer.id);
      this.contracts = await this.getContractsByCustomer(this.user.customer.id);
      this.contractInvoices = await this.buildIvoicesList();
    },
    async buildIvoicesList() {
      this.isLoading = true;
      const contractInvoices = [];
      for (const contract of this.contracts) { // eslint-disable-line
        try {
          const invoices = await this.getInvoices(contract.id); // eslint-disable-line
          contractInvoices.push({ contractId: contract.id, ...invoices });
        } catch (err) {
          // execution continues here when an error was thrown. You can also inspect the `ex`ception object
        }
      }
      this.isLoading = false;
      return contractInvoices;
    },
    async closePaymentMethod() {
      this.$refs.newPaymentMethod.closeModal();
      this.paymentMethods = await this.getPaymentMethods(this.user.customer.id);
    },
    openBuyHarp(contract) {
      this.setContract(contract);
      this.$refs.buyHarp.openModal();
    },
    async buyHarp() {
      const resp = await this.buyGood({ customerId: this.user.customer.id, contractId: this.contract.id });
      if (resp) {
        this.toast.success(this.$t('customerDashboard.wantToBuyOK'));
        this.contracts = this.contracts.map((contract) => (contract.id === resp.id ? resp : contract));
        this.$refs.buyHarp.closeModal();
        return;
      }
      this.toast.error(this.$t('customerDashboard.wantToBuyKO'));
      this.$refs.buyHarp.closeModal();
    },
    openGiveHarpBack(contract) {
      this.setContract(contract);
      this.$refs.giveHarpBack.openModal();
    },
    async giveHarpBack() {
      const resp = await this.sendBackGood({ customerId: this.user.customer.id, contractId: this.contract.id });
      if (resp) {
        this.toast.success(this.$t('customerDashboard.stopRentalOK'));
        this.contracts = this.contracts.map((contract) => (contract.id === resp.id ? resp : contract));
        this.$refs.giveHarpBack.closeModal();
        return;
      }
      this.toast.error(this.$t('customerDashboard.stopRentalKO'));
      this.$refs.giveHarpBack.closeModal();
    },
    async logout() {
      await this.signOut();
      this.$router.push('/');
    },
    showButtons(rentDate, contractId) {
      const newDate = dayjs(rentDate).add(6, 'month');
      const nextDate = this.contractInvoices.length > 0 ? this.contractInvoices.filter((item) => item.contractId === contractId)[0].next_invoice * 1000 : null;
      const nextInvoice = dayjs(nextDate).subtract(15, 'day');
      return dayjs() > newDate && dayjs() < nextInvoice;
    },
    disableButton(contract) {
      return this.workflowStates.some((status) => status === contract.workflow_status);
    },
    invoicesInfo(contractId) {
      return this.contractInvoices.length > 0
        ? this.contractInvoices.filter((item) => item.contractId === contractId).map((item) => item.invoices).flat()
        : null;
    },
    nextPaymentDate(contractId) {
      if (this.contractInvoices.length > 0) {
        return this.contractInvoices.filter((item) => item.contractId === contractId)[0].next_invoice
          ? this.$d(new Date(this.contractInvoices.filter((item) => item.contractId === contractId)[0].next_invoice * 1000), 'short', this.$i18n.locale)
          : this.$t('noDate');
      }
      return this.$t('noDate');
    },
    invoiceDate(invoiceDate) {
      return invoiceDate ? this.$d(new Date(invoiceDate * 1000), 'short', this.$i18n.locale) : '';
    },
  },
};
</script>
