<template>
    <p class="text-xl font-medium text-stone-700 dark:text-gray-100">{{ $t('contracts.customer') }}</p>
    <div class="grid lg:grid-cols-2 w-full text-lg gap-x-12 gap-y-2 mb-6">
      <span v-if="isNotPerson">
        {{ $t('customers.company') }}
        <p class="data">{{ customer.company || '' }}</p>
      </span>
      <span v-if="!isNotPerson">
        {{ $t('customers.firstname') }}
        <p class="data">{{ customer.firstname || '' }}</p>
      </span>
      <span v-if="!isNotPerson">
        {{ $t('customers.lastname') }}
        <p class="data">{{ customer.lastname || '' }}</p>
      </span>
      <span>
        {{ $t('customers.email') }}
        <p class="data">{{ customer.email || '' }}</p>
      </span>
      <span>
        {{ $t('customers.billingAddress') }}
        <p class="data">{{ customer && customer.billing_address ? customer.billing_address.address : '' }}</p>
      </span>
     <span>
        {{ $t('customers.co') }}
        <p class="data">{{ customer && customer.billing_address ? customer.billing_address.co : '' }}</p>
      </span>
      <span>
        {{ $t('customers.postcode') }}
        <p class="data">{{ customer && customer.billing_address ? customer.billing_address.postcode : '' }}</p>
      </span>
      <span>
        {{ $t('customers.city') }}
        <p class="data">{{ customer && customer.billing_address ? customer.billing_address.city : '' }}</p>
      </span>
      <span>
        {{ $t('customers.state') }}
        <p class="data">{{ customer && customer.billing_address ? customer.billing_address.state : '' }}</p>
      </span>
      <span>
        {{ $t('customers.country') }}
        <p class="data">{{ customer && customer.billing_address ? customer.billing_address.country : '' }}</p>
      </span>
      <base-table
        :iconFields="false"
        :addIcon="false"
        :visible-fields="addressFields"
        :items="[shippingAddress]"
        :table-title="$t('customers.shippingAddresses')"
        class="col-span-2"
        header-cell-style="bg-emerald-200 text-emerald-400 last:hidden"
      ></base-table>
    </div>
    <p class="text-xl font-medium text-stone-700 dark:text-gray-100">{{ $t('contracts.good')}}</p>
    <div class="grid lg:grid-cols-2 w-full text-lg gap-x-12 gap-y-2 mb-6">
      <span>
        {{ $t('harps.model') }}
        <p class="data">{{ good.harp_sku || ''  }}</p>
      </span>
      <span>
        {{ $t('goods.serial') }}
        <p class="data">{{ good.serial_no || ''  }}</p>
      </span>
      <span>
        {{ $t('goods.color') }}
        <p class="data">{{ good.color_slug || ''  }}</p>
      </span>
      <span>
        {{ $t('harps.description') }}
        <p class="data">{{ good && good.harp ? good.harp.description : ''  }}</p>
      </span>
    </div>
    <p class="text-xl font-medium text-stone-700 dark:text-gray-100">{{$t('contract_templates.contract_templates')}}</p>
    <div class="grid lg:grid-cols-2 w-full text-lg gap-x-12 gap-y-2">
      <span>
        {{ $t('contract_templates.description') }}
        <p class="data">{{ contractTemplate.description || ''  }}</p>
      </span>
      <span>
        {{ $t('contract_templates.withdrawal_on_the_spot') }}
        <p class="data">{{ contractTemplate.withdrawal_on_the_spot ? $t('yes') : $t('no')  }}</p>
      </span>
      <span v-if="contractTemplate.fee">
        {{ $t('contract_templates.fee') }}
        <p class="data">{{ $n((contractTemplate.fee / 100), 'currency') || '' }}</p>
      </span>
      <span v-if="contractTemplate.shipping_costs">
        {{ $t('contract_templates.shipping_costs') }}
        <p class="data">{{ $n((contractTemplate.shipping_costs / 100), 'currency') || '' }}</p>
      </span>
      <span v-if="contractTemplate.variable_fee">
        {{ $t('contract_templates.variable_fee') }}
        <p class="data">{{ contractTemplate.variable_fee ? $t('yes') : $t('no') }}</p>
      </span>
      <span v-if="contractTemplate.variable_fee">
        {{ $t('contract_templates.next_fee') }}
        <p class="data">{{ $n((contractTemplate.next_fee / 100), 'currency') || '' }}</p>
      </span>
    </div>
</template>

<script>
/* eslint-disable object-curly-newline, camelcase, vue/no-unused-components */
import { mapGetters } from 'vuex';
import BaseTable from '../base/BaseTable.vue';

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      addressFields: [
        {
          field: 'company',
          label: this.$t('customers.fullname'),
        },
        {
          field: 'address',
          label: this.$t('customers.address'),
        },
        {
          field: 'postcode',
          label: this.$t('customers.postcode'),
        },
        {
          field: 'city',
          label: this.$t('customers.city'),
        },
        {
          field: 'state',
          label: this.$t('customers.state'),
        },
        {
          field: 'country',
          label: this.$t('customers.country'),
        },
        {
          field: 'phone',
          label: this.$t('customers.phone'),
        },
        {
          field: 'note',
          label: this.$t('customers.note'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('customers', ['customer', 'shippingAddress']),
    ...mapGetters('goods', ['good']),
    ...mapGetters('contractTemplates', ['contractTemplate']),
    isNotPerson() {
      return this.customer.type === 'G';
    },
    feeBasisTranslation() {
      const feeBasisTranslations = {
        daily: this.$t('contract_templates.daily'),
        monthly: this.$t('contract_templates.monthly'),
        quarterly: this.$t('contract_templates.quarterly'),
      };
      return feeBasisTranslations[this.contractTemplate.fee_basis];
    },
  },
};
</script>

<style scoped>
  span {
    @apply border-l-2 pl-2 border-emerald-400 flex flex-col md:flex-row
  }
  .data {
    @apply font-thin border-stone-400 md:pl-2
  }
</style>
