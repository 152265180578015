<template>
  <!-- eslint-disable -->
  <form class="flex flex-col text-gray-700 dark:text-white font-thin" @submit.prevent="saveGood">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-1">
      <base-object-select
        :label="$t('goods.harp_model')"
        v-model="editedGood.harp_sku"
        required="true"
        :options="harpsOptions"
        valueField="sku"
        descriptionField="description"
      />
      <input-field
        :name="$t('goods.serial')"
        v-model="editedGood.serial_no"
        :required="true"
        borderStyle="border-goods"
      />
      <base-object-select
        :label="$t('goods.stringsLayoutSlug')"
        :options="stringsLayoutsOptions"
        v-model="editedGood.strings_layout_slug"
        :required="true"
        valueField="strings_layout_slug"
        descriptionField="description"
      />
      <base-object-select
        :label="$t('goods.color')"
        v-model="editedGood.color_slug"
        :required="true"
        :options="harpColorsOptions"
        valueField="slug"
        descriptionField="color"
      />
      <base-object-select
        :label="$t('goods.state')"
        :options="goodStates"
        v-model="editedGood.state"
        :required="true"
        valueField="id"
        descriptionField="description"
      />
      <base-object-select
        label="Location"
        v-model="editedGood.good_location"
        :options="goodLocations"
        valueField="id"
        descriptionField="description"
        :required="true"
      />
      <label for="note" class="flex flex-col col-span-2 mt-2">
        {{ $t("goods.notes") }}
        <textarea id="note" v-model="editedGood.note"
          class="bg-transparent rounded-md p-2 border border-gray-400 dark:border-gray-100"
        />
      </label>
    </div>
    <base-button extra-class="border-violet-400 text-violet-400 hover:bg-violet-300 self-end mt-3">
      {{ $t("harps.save") }}
    </base-button>
  </form>
</template>

<script>
/* eslint-disable camelcase, object-curly-newline */
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';
import currencyCode from '../../config/currency-code';
import InputField from '../graphics/InputField.vue';
import BaseButton from '../base/BaseButton.vue';
import BaseObjectSelect from '../base/BaseObjectSelect.vue';

export default {
  name: 'EditGood',

  components: {
    InputField,
    BaseButton,
    BaseObjectSelect,
  },

  data() {
    return {
      editedGood: {
        id: null,
        harp_sku: '',
        serial_no: '',
        strings_layout_slug: '',
        color_slug: '',
        state: '',
        good_location: '',
        note: '',
      },

      currency: currencyCode.currency,

      goodStates: [
        { id: 'new', description: this.$t('goods.new') },
        { id: 'used', description: this.$t('goods.used') },
      ],
      goodLocations: [
        {
          id: 'piasco',
          description: 'Piasco',
        },
        {
          id: 'milano',
          description: 'Milano',
        },
      ],
    };
  },

  setup() {
    const toast = useToast();

    return { toast };
  },

  async created() {
    await this.getHarps();
    await this.getStringsLayouts();
    await this.getHarpColors();
    this.editedGood = (({ id, harp_sku, serial_no, strings_layout_slug, color_slug, state, good_location, note }) => ({ id, harp_sku, serial_no, strings_layout_slug, color_slug, state, good_location, note }))(this.good);
  },

  computed: {
    ...mapGetters('harps', ['harps']),
    ...mapGetters('goods', ['good']),
    ...mapGetters('stringsLayouts', ['stringsLayouts']),
    ...mapGetters('harpColors', ['harpColors']),

    harpsOptions() {
      return this.harps.map((harp) => ({ sku: harp.sku, description: harp.description }));
    },

    stringsLayoutsOptions() {
      return this.stringsLayouts.map((layout) => ({
        strings_layout_slug: layout.slug,
        description: layout.description,
      }));
    },

    harpColorsOptions() {
      return this.harpColors.map((color) => ({
        slug: color.slug,
        color: color.color,
      }));
    },
  },

  methods: {
    ...mapActions('harps', ['getHarps']),
    ...mapActions('goods', ['putGood']),
    ...mapActions('stringsLayouts', ['getStringsLayouts']),
    ...mapActions('harpColors', ['getHarpColors']),
    async saveGood() {
      this.editedGood.serial_no = this.editedGood.serial_no.toUpperCase();
      await this.putGood(this.editedGood);
      this.toast.success(this.$t('goods.updated'));
      this.$emit('close');
    },
  },
};
</script>
