<template>
  <div class="relative flex flex-col text-stone-700 dark:text-gray-100 rounded-xl">
    <div class="grid grid-flow-col text-center border border-emerald-300 text-emerald-400 bg-transparent w-full rounded-lg mb-6 font-light md:text-xl overflow-hidden">
      <button
        v-for="(tab, index) in dataTabs" :key="tab.label"
        @click="setTabId(index)"
        class="p-3 border-r last:border-r-0 border-white dark:border-slate-600 capitalize
        hover:bg-emerald-100 dark:hover:bg-slate-700
        disabled:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40"
        :class="tabSelected(index)"
      >
        {{ tab.label }}
      </button>
    </div>
    <div v-for="(tab, index) in dataTabs" :key="tab.component">
      <form @submit.prevent.stop="saveContract" v-if="this.tabNumber === index">
        <component
          :is="tab.component"
        />
        <div class="flex mt-3" :class="tabNumber === 0 ? 'justify-end' : 'justify-between'">
          <base-button v-if="tabNumber > 0" @click="previousTab()" extra-class="border-emerald-400 hover:bg-emerald-300 text-emerald-400">
            {{ $t('contracts.back')}}
          </base-button>
          <base-button v-if="tabNumber < totalTabs" @click="nextTab()" extra-class="border-emerald-400 hover:bg-emerald-300 text-emerald-400">
            {{ $t('contracts.next')}}
          </base-button>
          <base-button v-if="tabNumber == totalTabs" extra-class="border-emerald-400 hover:bg-emerald-300 text-emerald-400 disabled:cursor-not-allowed
          disabled:border-gray-300 disabled:text-gray-300 disabled:bg-gray-100 dark:disabled:bg-slate-500 dark:disabled:hover:text-gray-300"
          type="submit" :disabled="isFormInvalid">
            {{ endButton }}
          </base-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline, camelcase, vue/no-unused-components */
import { useToast } from 'vue-toastification';
import { mapGetters, mapActions } from 'vuex';
import BaseButton from './BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
  props: {
    endButton: {
      type: String,
      default: 'Done',
    },
    dataTabs: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      tabNumber: 0,
      totalTabs: 0,
      currentTabIndex: 0,
      contractCreated: false,
    };
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  created() {
    this.totalTabs = this.dataTabs.length - 1;
  },
  computed: {
    ...mapGetters('goods', ['good']),
    ...mapGetters('customers', ['customer', 'shippingAddress']),
    ...mapGetters('contractTemplates', ['contractTemplates', 'contractTemplate']),
    isFormInvalid() {
      return !this.customer.id || !this.good.id || !this.contractTemplate.id || this.contractCreated;
    },
  },
  methods: {
    ...mapActions('contracts', ['postContract', 'setContract']),
    ...mapActions('customers', ['setCustomer', 'setShippingAddress']),
    ...mapActions('goods', ['setGood']),
    ...mapActions('contractTemplates', ['setContractTemplate']),
    setTabId(index) {
      this.tabNumber = index;
      this.currentTabIndex = this.tabNumber;
    },
    tabSelected(index) {
      return this.tabNumber === index ? 'bg-emerald-100 dark:bg-slate-700' : '';
    },
    nextTab() {
      this.currentTabIndex += 1;
      this.setTabId(this.currentTabIndex);
    },
    previousTab() {
      this.currentTabIndex -= 1;
      this.setTabId(this.currentTabIndex);
    },
    checkForm() {
      if (this.good === '' || this.good === null || this.good.value === 0) {
        this.message = 'Please enter text in text box below';
      }
    },
    async saveContract() {
      this.contractCreated = true;
      // TODO: [SR-104] update the payment_method management after the laravel package "Cashier" implementation
      const {
        customer_id, company, firstname, lastname, co, address, postcode, city, state, country,
      } = this.customer.billing_address;

      const {
        firstname: shipping_firstname, lastname: shipping_lastname, company: shipping_company,
        address: shipping_address, postcode: shipping_postcode, city: shipping_city,
        state: shipping_state, country: shipping_country, co: shipping_co,
      } = this.shippingAddress;
      const { panthera_customer_id } = this.customer;
      const { id: good_id, serial_no: good_serial_no, harp_sku: good_description } = this.good;
      const {
        fee, fee_basis, variable_fee, fee_vary_after, next_fee,
        shipping_costs, deposit, accessories, stripe_product_id,
        rental_item_id, withdrawal_on_the_spot,
      } = this.contractTemplate;

      const payment_method = this.customer.pm_type;

      const contract = {
        customer_id,
        company,
        firstname,
        lastname,
        co,
        address,
        postcode,
        city,
        state,
        country,
        shipping_firstname,
        shipping_lastname,
        shipping_company,
        shipping_co,
        shipping_address,
        shipping_postcode,
        shipping_city,
        shipping_state,
        shipping_country,
        panthera_customer_id,
        good_id,
        good_serial_no,
        good_description,
        fee,
        fee_basis,
        variable_fee,
        fee_vary_after,
        next_fee,
        payment_method,
        stripe_product_id,
        rental_item_id,
        shipping_costs,
        deposit,
        withdrawal_on_the_spot,
        is_active: true,
        workflow_status: 'draft',
        pdf_url: '',
        accessories: accessories ? accessories.map((accessory) => accessory.id) : [],
      };

      await this.postContract(contract);
      this.toast.success(this.$t('contracts.created'));
      // reset contract objects
      this.setContractTemplate({});
      this.setCustomer({});
      this.setGood({});
      this.setShippingAddress({});

      this.$emit('close');
    },
  },
};
</script>
