export default {
  "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonar is another fine product of The Nonsense Factory 2023 - "])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "warn_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WARNING!"])},
  "warn_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The record will be deleted?"])},
  "warn_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
  "noMatchFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No match found"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noleggi", "@", "salvimusic.it"])},
  "noDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No date available"])},
  "auth": {
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These credentials do not match our records."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided password is incorrect."])},
    "throttle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many login attempts. Please try again in :seconds seconds."])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contracts signed"])}
  },
  "resetPassword": {
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])}
  },
  "forgotPassword": {
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "login": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "log-in-sonar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to Sonar"])},
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run your\n rental easily!"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update password"])},
    "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat new password"])}
  },
  "buttons": {
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDIT"])},
    "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD NEW"])},
    "importExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMPORT FROM EXCEL"])},
    "closeContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLOSE CONTRACT"])},
    "cancelContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCEL CONTRACT"])},
    "contractInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTRACT INFO"])},
    "createContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATE CONTRACT"])},
    "goodSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOOD SOLD"])}
  },
  "dashboard": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "waiting-to-be-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waiting to be signed"])},
    "currently-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currently active"])},
    "waiting-for-return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waiting for return"])},
    "due-to-expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["due to expire"])},
    "total-monthly-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total monthly fee"])},
    "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shipped"])},
    "ready-to-be-shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ready to be shipped"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed contracts"])},
    "email-viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viewed e-mails"])},
    "buy_good_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer wants to buy the harp"])},
    "stop_rent_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer wants to stop rent"])},
    "outstanding_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracts with Oustanding Payments"])},
    "active_by_18_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active contracts from 18 months at least"])}
  },
  "contract_templates": {
    "contract_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Templates"])},
    "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Duration in months"])},
    "newTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Contract Template"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Contract Template"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Template created successfully"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Template updated"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "fee_basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Basis"])},
    "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quarterly"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly"])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daily"])},
    "variable_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a variable fee?"])},
    "vary_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After how many cycles does the fee change?"])},
    "next_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Fee"])},
    "shipping_costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Costs"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "harps_related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harps Related"])},
    "rule_definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Rule Definition"])},
    "rule_good_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Status"])},
    "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance?"])},
    "good_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Group"])},
    "good_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Status"])},
    "select_harp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Harp"])},
    "product_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Product Line"])},
    "select_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Family"])},
    "family_lever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lever"])},
    "family_pedal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedal"])},
    "good_group_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["family"])},
    "good_group_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["line"])},
    "good_group_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model"])},
    "good_status_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
    "good_status_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
    "good_status_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["used"])},
    "save-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessories"])},
    "select_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select accessories"])},
    "rental_item_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera Item Id"])},
    "stripe_product_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe Product Id"])},
    "withdrawal_on_the_spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal on the spot"])}
  },
  "contracts": {
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracts"])},
    "contractList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracts list"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract's data"])},
    "contractNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract number"])},
    "workflowStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow status"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "pdfUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF url"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Active"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "createPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create PDF"])},
    "uploadPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new PDF"])},
    "noContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contracts yet."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract created successfully"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose contracts file:"])},
    "uploadCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload contracts from file"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Contract"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Contract"])},
    "noFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing XLS File"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracts have been imported"])},
    "panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera Code"])},
    "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in this field"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
    "harp_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harp Model"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiring Date"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Date"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "month_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month price"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "not_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Active"])},
    "select_good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the good for the contract"])},
    "select_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the customer for the contract"])},
    "another_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a different customer"])},
    "another_good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a different good"])},
    "another_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a different contract template"])},
    "another_shipping_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a different shipping address"])},
    "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Number"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent price"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "select_harp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the harp model for the good"])},
    "priceNoZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning! The price cannot be equal to 0"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["summary"])},
    "orderFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order for"])},
    "was_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contract was closed"])},
    "was_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contract was cancelled"])},
    "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Send Email"])},
    "emailSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "emailSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select email type"])},
    "emailRequestSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to send email done"])},
    "sellActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Sold/Continue Rent"])},
    "selectAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the action"])},
    "execute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute"])},
    "actionExecuted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action Executed"])},
    "good_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This good was sold"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
    "emailTypes": {
      "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optin"])},
      "collectPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect Pyament Method"])},
      "stopRent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Rental Info's"])},
      "goodShipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Shipped"])},
      "dashboardInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard info's"])}
    },
    "goodActionList": {
      "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Sold"])},
      "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume Rent"])}
    },
    "workflowStates": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draft"])},
      "agreement_created_waiting_to_be_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agreement sent to be signed"])},
      "agreement_email_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agreement mail viewed"])},
      "agreement_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agreement signed"])},
      "ready_to_be_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good ready to be shipped"])},
      "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good shipped"])},
      "buyGoodRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buy good requested"])},
      "stopRentRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sand back good requested"])},
      "waiting_for_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wait to returning good"])},
      "good_returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good returned"])},
      "returned_good_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checking good"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["closed"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelled"])}
    },
    "landingPages": {
      "registred_payment_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registred Payment methods"])},
      "confirm_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for choosing our rental service!"])},
      "rent_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent request"])},
      "contract_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract signed"])},
      "insert_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
      "save_new_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Payment method"])},
      "next_email_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon you'll receive an email from Adobe with the contract and with Adobe Sign you'll be able to sign it easily online."])},
      "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello"])},
      "select_payment_method_please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for choosing our rental service, this is the last step needed. Please insert your payment data below to proceed."])},
      "select_sepa_payment_method_please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want, you can add a sepa payment method clicking on the 'Yes' button and compiling the form will be showed on the screen. This will be your default payment method."])},
      "card_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card owner"])},
      "payment_method_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method owner"])},
      "process_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["process payment"])},
      "payment_method_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method accepted!"])},
      "next_email_harp_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All done! We'll process your request and notify you when the harp is shipped"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
      "already_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you. We have already received your request and we'are processing it."])},
      "generate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred processing your request. Pleas contact our service center"])},
      "add_sepa_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to add a SEPA payment method ?"])}
    },
    "showContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show contract"])},
    "reserveFunds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve funds"])},
    "reserveFundsSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The funds were reserved"])},
    "createContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate contract for this customer"])},
    "removePaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove payment method"])},
    "removePaymentMethodSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method removed"])},
    "shippingTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping to"])}
  },
  "customers": {
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Entity"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "sdi_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SDI Code"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullname"])},
    "co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C/O"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "defaultAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "pec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PEC"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "noContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contracts yet."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose customers file:"])},
    "uploadCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload customers from file"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Customer"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Customer"])},
    "noFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing XLS File"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers have been imported"])},
    "panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera Code"])},
    "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the country"])},
    "selectState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select State"])},
    "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in this field"])},
    "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
    "shippingAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping addresses"])},
    "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping address"])},
    "preferred_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Language"])},
    "addShippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Shipping Address"])},
    "editAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Address"])},
    "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Address"])},
    "customer_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer added successfully"])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer invalid"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer updated successfully"])},
    "nothingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing Found"])},
    "tabs": {
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
      "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracts"])}
    },
    "taxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number"])},
    "vatId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT ID"])},
    "validTaxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Tax Number seems valid"])},
    "validVatNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The VAT Number seems valid"])},
    "biodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["biodata"])},
    "landing": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the Stripe link is expired or you already have an active Stripe payment method."])}
    },
    "panthera_customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera customers"])},
    "get_panthera_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["get panthera code"])},
    "new_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new Panthera customer"])},
    "select_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select Panthera's customer"])},
    "customer_not_in_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer you are looking for isn't in the list?"])},
    "customer_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer you are looking for doesn't match any of our records"])},
    "add_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add a new Panthera customer"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm"])},
    "toast_create_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added Panthera customer successfully"])},
    "toast_panthera_customer_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer not found"])},
    "toast_customer_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera customer selected correctly"])},
    "toast_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "duplicated_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error inserting new customer. Please check you're not inserting duplicated data"])},
    "want_to_add_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create a contract for this customer ?"])},
    "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email is not valid"])}
  },
  "goods": {
    "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "noContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contracts yet."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose goods file:"])},
    "uploadCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload goods from file"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Good"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Good"])},
    "noFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing XLS File"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods have been imported"])},
    "panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera Code"])},
    "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in this field"])},
    "harp_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harp Model"])},
    "harp_model_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harp Model Code"])},
    "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Number"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "stringsLayoutSlug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strings Layout Slug"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price for rental"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "select_harp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the harp model for the good"])},
    "priceNoZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning! The price cannot be equal to 0"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import goods from Excel"])},
    "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods per page"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by description"])},
    "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods imported"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good updated successfully"])},
    "inUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good already in use by another Panthera Service Order"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good can be used on Panthera"])},
    "addGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harp part number decoder"])},
    "harpDecoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decode harp part"])},
    "insertGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert harp manually"])},
    "decode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decode"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good added successfully"])},
    "allLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All locations"])}
  },
  "harp-colors": {
    "addHarpColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an Harp Colors"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Harp Colors"])},
    "harp-colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harp Colors List"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "codeDigit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Digit"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Import"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Harp Colors from Excel"])},
    "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harp Colors Imported"])},
    "allColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All colors"])}
  },
  "harps-family": {
    "addHarpsFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an Harps Family"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Harps Family"])},
    "harps-families": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harps Families List"])},
    "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family"])},
    "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Import"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Harps Families from Excel"])},
    "importLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Import File"])},
    "importBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import!"])}
  },
  "harps": {
    "harpsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harps List"])},
    "harps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harps"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose harps file:"])},
    "uploadHarps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload harps from file"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Harp"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Harp"])},
    "noFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing XLS File"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harps have been imported"])},
    "panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera Code"])},
    "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in this field"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harp Model"])},
    "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "product_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product line"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price for rental"])},
    "select_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the harp family"])},
    "select_product_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the product line"])},
    "select_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the brand"])},
    "priceNoZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning! The price cannot be equal to 0"])},
    "harps-families": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harps Families"])},
    "strings-layouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strings Layouts"])},
    "harp-colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harps Colors"])},
    "harp-accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harps Accessories"])},
    "configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])},
    "serials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial numbers"])},
    "product-lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Lines"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Harps from Excel"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harp updated successfully"])},
    "allModels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Models"])}
  },
  "importer": {
    "importLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload the Excel File"])},
    "importBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
    "errors": {
      "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during the excel data import. Please check your excel data are correct and retry."])}
    }
  },
  "pagination": {
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&laquo; Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next &raquo;"])}
  },
  "passwords": {
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been reset!"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have emailed your password reset link!"])},
    "throttled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait before retrying."])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password reset token is invalid."])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can't find a user with that email address."])}
  },
  "product-lines": {
    "addProductLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Product Line"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Product Line"])},
    "product-lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Lines List"])},
    "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Line"])},
    "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Import"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Product Lines from Excel"])},
    "importLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Import File"])},
    "importBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import!"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Line Added"])},
    "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product lines imported"])}
  },
  "roles": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])}
  },
  "strings-layouts": {
    "addStringsFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Strings Layout"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Strings Layout"])},
    "strings-layouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strings Layouts List"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Import"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Strings Layouts from Excel"])},
    "importLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Import File"])},
    "importBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import!"])},
    "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strings Layouts Imported"])},
    "strings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strings"])},
    "allStrings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Strings Types"])}
  },
  "accessories": {
    "accessoriesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessories List"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Accessory"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Accessory"])},
    "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessories"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Accessories from Excel"])},
    "itemCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Code"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "accessory_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessory added successfully"])},
    "accessory_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessory has been updated"])},
    "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessories have been imported"])}
  },
  "user": {
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello"])},
    "create-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create a new key"])},
    "new-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new key"])},
    "your-api-keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your API keys"])},
    "keys-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you have a list of the API keys you created, keep in mind that once you generate them they are no longer viewable for security reasons."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
    "last_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last used at"])},
    "never_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never used"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ok"])},
    "how-to-add-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to generate a new key, enter a name and then click on \"Create a new key +\"."])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attention!"])},
    "copy-token-please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't forget to copy the key and store it safely, because this it's the only time it'll be displayed to you."])}
  },
  "validation": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be accepted."])},
    "accepted_if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be accepted when :other is :value."])},
    "active_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute is not a valid URL."])},
    "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a date after :date."])},
    "after_or_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a date after or equal to :date."])},
    "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must only contain letters."])},
    "alpha_dash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must only contain letters, numbers, dashes and underscores."])},
    "alpha_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must only contain letters and numbers."])},
    "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be an array."])},
    "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a date before :date."])},
    "before_or_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a date before or equal to :date."])},
    "between": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be between :min and :max."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be between :min and :max kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be between :min and :max characters."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must have between :min and :max items."])}
    },
    "boolean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field must be true or false."])},
    "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute confirmation does not match."])},
    "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password is incorrect."])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute is not a valid date."])},
    "date_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a date equal to :date."])},
    "date_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute does not match the format :format."])},
    "different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute and :other must be different."])},
    "digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be :digits digits."])},
    "digits_between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be between :min and :max digits."])},
    "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute has invalid image dimensions."])},
    "distinct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field has a duplicate value."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a valid email address."])},
    "ends_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must end with one of the following: :values."])},
    "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected :attribute is invalid."])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a file."])},
    "filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field must have a value."])},
    "gt": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be greater than :value."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be greater than :value kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be greater than :value characters."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must have more than :value items."])}
    },
    "gte": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be greater than or equal :value."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be greater than or equal :value kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be greater than or equal :value characters."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must have :value items or more."])}
    },
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be an image."])},
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected :attribute is invalid."])},
    "in_array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field does not exist in :other."])},
    "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be an integer."])},
    "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a valid IP address."])},
    "ipv4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a valid IPv4 address."])},
    "ipv6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a valid IPv6 address."])},
    "json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a valid JSON string."])},
    "lt": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be less than :value."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be less than :value kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be less than :value characters."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must have less than :value items."])}
    },
    "lte": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be less than or equal :value."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be less than or equal :value kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be less than or equal :value characters."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must not have more than :value items."])}
    },
    "max": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must not be greater than :max."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must not be greater than :max kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must not be greater than :max characters."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must not have more than :max items."])}
    },
    "mimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a file of type: :values."])},
    "mimetypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a file of type: :values."])},
    "min": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be at least :min."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be at least :min kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be at least :min characters."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must have at least :min items."])}
    },
    "multiple_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a multiple of :value."])},
    "not_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected :attribute is invalid."])},
    "not_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute format is invalid."])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a number."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password is incorrect."])},
    "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field must be present."])},
    "regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute format is invalid."])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field is required."])},
    "required_if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field is required when :other is :value."])},
    "required_unless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field is required unless :other is in :values."])},
    "required_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field is required when :values is present."])},
    "required_with_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field is required when :values are present."])},
    "required_without": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field is required when :values is not present."])},
    "required_without_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field is required when none of :values are present."])},
    "prohibited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field is prohibited."])},
    "prohibited_if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field is prohibited when :other is :value."])},
    "prohibited_unless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute field is prohibited unless :other is in :values."])},
    "same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute and :other must match."])},
    "size": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be :size."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be :size kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be :size characters."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must contain :size items."])}
    },
    "starts_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must start with one of the following: :values."])},
    "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a string."])},
    "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a valid timezone."])},
    "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute has already been taken."])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute failed to upload."])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a valid URL."])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The :attribute must be a valid UUID."])},
    "codice_fiscale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Tax Number provided seems not to be correct. Could you check it?"])},
    "vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The VAT Number seems incorrect or the VAT is not active. Could you check it?"])},
    "custom": {
      "attribute-name": {
        "rule-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["custom-message"])}
      }
    },
    "attributes": [
      
    ]
  },
  "stripe": {
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment data"])},
    "holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card holder"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Payment Method"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new card"])},
    "enterPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a payment method"])},
    "authRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication required to authorize payment"])},
    "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry:"])},
    "mine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My payment methods"])},
    "error_element": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: fill all fields in the form"])},
    "error_after_confirmSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error after confirmSetup"])},
    "error_trying_confirmSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error trying confirmSetup"])}
  },
  "customerDashboard": {
    "payment_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered payment methods"])},
    "no_payment_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no payment methods registered"])},
    "add_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New payment method"])},
    "buy_harp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy the harp"])},
    "attention_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! By doing this, you will initiate the purchase process. To receive a quote, send an email to "])},
    "confirm_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you confirm your interest in buying the harp you are currently renting? After receiving your confirmation, a member of our team will get in touch with you to proceed in the purchase."])},
    "return_harp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return the harp"])},
    "confirm_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you confirm you'd like to return the harp you are currently renting? After receiving your confirmation, our team will start the harp retrieval process."])},
    "your_rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your rental"])},
    "your_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payments"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear"])},
    "your_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this is your reserved area, a page where you can easily monitor your rental status.\n Through this interface you can add new payment methods, request to end the rental and collection at your address, or, proceed to purchase the harp."])},
    "save_new_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Payment method"])},
    "wantToBuyOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for making the choice to purchase the Harp. Our staff will contact you soon."])},
    "wantToBuyKO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while completing the operation. Please, retry later"])},
    "stopRentalOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry that you want to interrupt the rental of the harp. Our staff will contact you soon to provaide you informations about the 'send back' procedure."])},
    "stopRentalKO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while completing the operation. Please, retry later"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract:"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rented since:"])},
    "errorOnPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred retrieving payments info's. Please, retry later"])},
    "nextPaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next payment date"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Pay"])},
    "stripeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe Id"])},
    "invoiceDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "paymentState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment State"])}
  },
  "api-errors": {
    "not-authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not authorized"])}
  }
}