<template>
  <base-autocomplete
    v-if="changeTemplate"
    :selection-list="contractTemplateSelectionList"
    @selected="contractTemplateSelection"
    v-model="contractTemplateSelected"
    lbl-text="Seleziona il template contratto"
    class="font-thin"
    :selectNotAvailable="areShippingCostsEdited"
  />
  <base-checkbox v-else
    :label="$t('contracts.another_template')"
    v-model="changeTemplate"
  />
  <div class="grid grid-cols-2 w-full text-lg gap-x-12 gap-y-2 mb-6 mt-3 font-thin">
    <base-input
      :label="$t('contract_templates.description')"
      v-model="contractTemplateData.description" disabled="disabled"
    />
    <base-currency-input
      :label="$t('contract_templates.fee')"
      v-model="contractTemplateData.fee" disabled="disabled"
    />
    <base-input
      v-show="contractTemplateData.variable_fee"
      :label="$t('contract_templates.vary_after')"
      v-model="contractTemplateData.fee_vary_after" disabled="disabled"
    />
    <base-currency-input
      v-show="contractTemplateData.variable_fee"
      :label="$t('contract_templates.next_fee')"
      v-model="contractTemplateData.next_fee" disabled="disabled"
    />
    <h4 class="font-medium uppercase text-red-600 underline text-base">
      {{ $t('contracts.shippingTo') }}: <span v-if="shippingAddress">{{shippingAddress.city}} - {{shippingAddress.state}}</span>
    </h4>
    <base-checkbox
      :label="$t('contract_templates.withdrawal_on_the_spot')"
      v-model="contractTemplateData.withdrawal_on_the_spot"
      @change="setWithdrawalOnTheSpot()"
    />
    <base-currency-input
      :label="$t('contract_templates.shipping_costs')"
      v-model.number="contractTemplateData.shipping_costs"
      @save-event="setShippingCost" @edit-event="areShippingCostsEdited = true" :disabled="!areShippingCostsEdited"
      icon
    />
    <base-currency-input
      :label="$t('contract_templates.deposit')"
      v-model="contractTemplateData.deposit" disabled="disabled"
    />
    <base-input
      :label="$t('contract_templates.fee_basis')"
      v-model="feeBasisTranslation" disabled="disabled"
    />
    <base-input
      :label="$t('contract_templates.duration')"
      v-model="contractTemplateData.duration" disabled="disabled"
    />
  </div>
</template>

<script>
/* eslint-disable object-curly-newline, camelcase, vue/no-unused-components */
import { mapGetters, mapActions } from 'vuex';
import BaseAutocomplete from '../base/BaseAutocomplete.vue';
import BaseInput from '../base/BaseInput.vue';
import BaseCheckbox from '../base/BaseCheckbox.vue';
import BaseCurrencyInput from '../base/BaseCurrencyInput.vue';

export default {
  components: {
    BaseAutocomplete,
    BaseInput,
    BaseCheckbox,
    BaseCurrencyInput,
  },
  data() {
    return {
      changeTemplate: false,
      areShippingCostsEdited: false,
      contractTemplateSelectionList: [],
      contractTemplateSelected: '',
      contractTemplateData: {
        id: null,
        slug: '',
        description: '',
        fee: 0,
        fee_basis: 'quarterly',
        variable_fee: 0,
        fee_vary_after: 12,
        next_fee: 0,
        shipping_costs: 0,
        deposit: 0,
        rule_good_status: '',
        duration: null,
        withdrawal_on_the_spot: false,
      },
    };
  },
  async created() {
    await this.getHarps();
    await this.getContractTemplates();
    this.initData();
  },
  watch: {
    contractTemplate: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.contractTemplateData = this.contractTemplateFields(this.contractTemplate);
          this.selectNewTemplate();
        }
      },
    },
  },
  computed: {
    ...mapGetters('customers', ['shippingAddress']),
    ...mapGetters('goods', ['good']),
    ...mapGetters('harps', ['harps']),
    ...mapGetters('contractTemplates', ['contractTemplates', 'contractTemplate']),
    contractTemplateFields() {
      return (template) => {
        const { created_at, updated_at, pivot, harps, ...remaining } = template;
        return remaining;
      };
    },
    feeBasisTranslation() {
      const feeBasisTranslations = {
        daily: this.$t('contract_templates.daily'),
        monthly: this.$t('contract_templates.monthly'),
        quarterly: this.$t('contract_templates.quarterly'),
      };
      return feeBasisTranslations[this.contractTemplateData.fee_basis];
    },
    goodStatus() {
      const goodStates = {
        all: this.$t('contract_templates.good_status_all'),
        new: this.$t('contract_templates.good_status_new'),
        used: this.$t('contract_templates.good_status_used'),
      };
      return goodStates[this.contractTemplateData.rule_good_status];
    },
  },
  methods: {
    ...mapActions('harps', ['getHarps']),
    ...mapActions('contractTemplates', ['setContractTemplate', 'getContractTemplates']),
    initData() {
      if (this.good && Object.keys(this.good).length > 0) {
        const [selectedHarp] = this.harps.filter((harp) => harp.sku === this.good.harp_sku);
        if (selectedHarp.contract_templates && selectedHarp.contract_templates.length > 0) {
          this.contractTemplateSelectionList = selectedHarp.contract_templates.map((ct) => ({ id: ct.id, text: `${ct.description}` }));
          const selectedCT = Object.keys(this.contractTemplate).length > 0 ? this.contractTemplate : selectedHarp.contract_templates[0];
          const accessories = this.contractTemplates.find((ct) => ct.id === selectedCT.id).accessories; // eslint-disable-line
          const contractTemplate = this.contractTemplateFields({ ...selectedCT, accessories, withdrawal_on_the_spot: false });
          this.setContractTemplate(contractTemplate);
          return;
        }
        this.setContractTemplate({});
        this.contractTemplateSelectionList = [];
      }
    },
    selectNewTemplate() {
      if (!this.contractTemplateData.id) {
        this.changeTemplate = true;
        return;
      }
      this.changeTemplate = false;
    },
    contractTemplateSelection(selected) {
      const [newCT] = this.contractTemplates.filter((ct) => ct.id === selected.id);
      this.setContractTemplate(newCT);
      this.changeTemplate = false;
    },
    setShippingCost() {
      this.setContractTemplate(this.contractTemplateData);
      this.areShippingCostsEdited = false;
    },
    setWithdrawalOnTheSpot() {
      this.setContractTemplate(this.contractTemplateData);
    },
  },
};
</script>
