<template>
  <form class="flex flex-col font-thin w-full text-lg space-y-5 dark:text-gray-100" @submit.prevent="updateContract">
    <div class="grid grid-cols-2 gap-x-3 gap-y-2">
      <div class="flex flex-col w-full justify-items-stretch">
        <p class="w-full text-2xl text-left capitalize font-medium">{{ $t("contracts.resendEmail") }}</p>
        <div class="flex w-full space-x-3">
          <base-object-select
            :label="$t('contracts.emailSelect')"
            :options="emailTypeList"
            valueField="id"
            descriptionField="description"
            v-model="selectedEmail"
          />

          <base-button
            :disabled="!selectedEmail"
            type="button"
            @click.stop.prevent="sendEmail()"
            extra-class="py-1 px-2 self-end border-emerald-400 text-emerald-400 hover:bg-emerald-300 cursor-pointer w-fit
              disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-300 justify-self-end disabled:border-gray-300
              disabled:hover:text-gray-300"
          >{{ $t("contracts.emailSend") }}
          </base-button>
        </div>
      </div>
      <div
        v-if="contractReceived.workflow_status === 'buy_good_requested'"
        class="grid grid-cols-3 gap-x-3 gap-y-2 justify-items-stretch"
      >
        <p class="col-span-3 text-2xl text-left capitalize font-medium">{{ $t("contracts.sellActions") }}</p>
        <base-object-select
          :label="$t('contracts.selectAction')"
          :options="goodActionList"
          valueField="id"
          descriptionField="description"
          v-model="selectedGoodAction"
          class="col-span-2"
        />

        <base-button
          :disabled="!selectedGoodAction"
          type="button"
          @click.stop.prevent="executeAction()"
          extra-class="py-2 self-end py-3 border-emerald-400 text-emerald-400 hover:bg-emerald-300 cursor-pointer
            disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-300 justify-self-end disabled:border-gray-300"
        >{{ $t("contracts.execute") }}
        </base-button>
      </div>
      <div v-if="contractReceived.workflow_status === 'agreement_signed' && isSuperAdmin"
      class="flex flex-col space-y-2 items-end justify-end">
        <base-button
          type="button"
          extra-class="py-1 px-2 self-end border-emerald-400 text-emerald-400 hover:bg-emerald-300 cursor-pointer w-fit"
          @click.stop.prevent="reserveFunds()"
        >
          {{ $t('contracts.reserveFunds') }}
        </base-button>
        <base-button
          type="button"
          extra-class="py-1 px-2 self-end border-emerald-400 text-emerald-400 hover:bg-emerald-300 cursor-pointer w-fit"
          @click.stop.prevent="removePaymentMethod()"
        >
          {{ $t('contracts.removePaymentMethod') }}
        </base-button>
      </div>

      <p class="col-span-2 mt-6 text-2xl text-left capitalize font-medium">{{ $t("contracts.data") }}</p>
      <base-input
        class="col-span-1"
        v-model="contractReceived.contract_no" disabled="disabled" :label="$t('contracts.contractNo')"
      />
      <div class="grid grid-cols-12">
        <base-input
          class="col-span-10"
          disabled="disabled"
          v-model="workflowState"
          :label="$t('contracts.workflowStatus')">
        </base-input>
        <div class="col-span-2 grid grid-flow-col gap-1 p-2">
          <!-- Il bottone qui sotto è stato temporaneamente disabilitato per evitare confusione all'utente .-->
          <!-- Verificare se lo stato "closed" può essere impostato manualmente ed in quali casi. .-->
          <!-- <button
            :title="$t('buttons.closeContract')"
            type="button"
            @click="closedStatus()"
            class="self-end justify-self-center"
            :disabled="isDeleteDisable"
            :class="isDeleteDisable ? 'text-gray-300 dark:text-gray-100' : 'text-red-500 hover:text-red-700 cursor-pointer'">
            <closed-icon class="responsive-icon" />
          </button> -->
          <button
            :title="$t('buttons.cancelContract')"
            type="button"
            @click="cancelledStatus()"
            class="self-end justify-self-center"
            :disabled="isCancelDisable"
            :class="isCancelDisable ? 'text-gray-300 dark:text-gray-100' : 'text-red-500 hover:text-red-700 cursor-pointer'"
          >
            <cancel-icon class="responsive-icon"/>
          </button>
        </div>
      </div>
      <base-text-area
        v-if="showNotesField"
        :disabled="isNotesDisabled"
        class="col-span-2"
        v-model="contractReceived.notes"
        :label="$t('contracts.note')">
      </base-text-area>
      <base-input
        class="col-span-1"
        v-model="contractReceived.payment_method" disabled="disabled" :label="$t('contracts.paymentMethod')"
      />
      <base-currency-input
        class="col-span-1"
        :label="$t('contract_templates.shipping_costs')"
        v-model.number="contractReceived.shipping_costs"
        disabled="disabled"
      />
      <base-currency-input
        class="col-span-1"
        :label="$t('contract_templates.deposit')"
        v-model="contractReceived.deposit"
        disabled="disabled"
      />
      <base-checkbox
        :label="$t('contract_templates.withdrawal_on_the_spot')"
        class="col-span-1 mt-6"
        v-model="contractReceived.withdrawal_on_the_spot"
        disabled="disabled"
      />
      <base-input
        class="col-span-1"
        v-model="contractIsActive" disabled="disabled" :label="$t('contracts.active') + '?'"
      />
      <base-input
        class="col-span-1"
        v-model="contractAccessories" disabled="disabled" :label="$t('accessories.accessories')"
      />
    </div>
    <div class="grid grid-cols-3 gap-x-3">
      <base-input
        isDate
        v-model="contractReceived.created_at" disabled="disabled" :label="$t('contracts.start')"
      />
      <base-input
        isDate
        v-model="contractReceived.expiration_date" disabled="disabled" label="Expriration date"
      />
      <base-input
        isDate
        v-model="contractReceived.updated_at" disabled="disabled" :label="$t('contracts.update')"
      />
    </div>
    <a :href="contractReceived.pdf_url" target="_blank" class="self-end" v-show="contractReceived.pdf_url">
      <base-button type="button" extra-class="border-emerald-400 text-emerald-400 hover:bg-emerald-300">
        {{ $t('contracts.showContract') }}
      </base-button>
    </a>
    <div class="grid grid-cols-2 gap-x-3 gap-y-2" v-if="isNotPerson">
      <p class="col-span-2 text-2xl text-left capitalize font-medium">{{ $t("contracts.customer") }}</p>
      <base-input
        v-model="contractReceived.company" disabled="disabled" :label="$t('customers.company')"
      />
    </div>
    <div class="grid grid-cols-2 gap-x-3 gap-y-2" v-if="!isNotPerson">
      <p class="col-span-2 text-2xl text-left capitalize font-medium">{{ $t("contracts.customer") }}</p>
      <base-input
        v-model="contractReceived.firstname" disabled="disabled" :label="$t('customers.firstname')"
      />
      <base-input
        v-model="contractReceived.lastname" disabled="disabled" :label="$t('customers.lastname')"
      />
    </div>
    <base-table
      :table-title="$t('customers.billingAddress')"
      :visible-fields="addressFields"
      :items="[contractReceived]"
      :addIcon="false"
      :iconFields="false"
      header-cell-style="bg-emerald-200 text-emerald-400 last:hidden"
    >
    </base-table>
    <base-table
      :table-title="$t('customers.shippingAddress')"
      :visible-fields="shippingAddressFields"
      :items="[contractReceived]"
      :addIcon="false"
      :iconFields="false"
      header-cell-style="bg-emerald-200 text-emerald-400 last:hidden"
    >
    </base-table>
    <div class="grid grid-cols-2 gap-x-3 gap-y-2">
      <p class="col-span-2 text-2xl text-left capitalize font-medium">{{ $t("contracts.good") }}</p>
      <base-input
        v-model="contractReceived.good_serial_no" disabled="disabled" :label="$t('goods.serial')"
      />
      <base-input
        v-model="contractReceived.good_description" disabled="disabled" :label="$t('goods.harp_model')"
      />
      <base-currency-input
        :label="$t('contract_templates.fee')"
        v-model="contractReceived.fee"
        disabled="disabled"
      />
      <base-input
        v-model="feeBasisTranslation" disabled="disabled" :label="$t('contract_templates.fee_basis')"
      />
    </div>

    <div v-if="invoicesLength > 0" class="grid grid-cols-1 gap-x-3 gap-y-2">
      <p class="col-span-2 text-2xl text-left capitalize font-medium">{{ $t("contracts.payments") }}</p>
      <payment-table :contract="contractReceived" :contract-invoices="contractInvoices" :no-stripe-id="false" />
    </div>

    <base-button
      extra-class="self-end border-emerald-400 text-emerald-400 hover:bg-emerald-300
        disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-300 disabled:border-gray-300
        disabled:hover:text-gray-300"
        :disabled="saveButtonDisabled"
    >
      {{$t('contracts.save')}}
    </base-button>
  </form>
</template>

<script>
/* eslint-disable camelcase */
import axios from 'axios';
import { useToast } from 'vue-toastification';
import { mapGetters, mapActions } from 'vuex';
import BaseInput from '../base/BaseInput.vue';
import BaseTable from '../base/BaseTable.vue';
import BaseButton from '../base/BaseButton.vue';
import CancelIcon from '../graphics/CancelIcon.vue';
// import ClosedIcon from '../graphics/ClosedIcon.vue'; // vedi commento in html
import BaseCurrencyInput from '../base/BaseCurrencyInput.vue';
import BaseObjectSelect from '../base/BaseObjectSelect.vue';
import PaymentTable from './PaymentTable.vue';
import BaseCheckbox from '../base/BaseCheckbox.vue';
import BaseTextArea from '../base/BaseTextArea.vue';

export default {
  components: {
    BaseInput,
    BaseTable,
    BaseButton,
    CancelIcon,
    // ClosedIcon, // vedi commento in html
    BaseCurrencyInput,
    BaseObjectSelect,
    PaymentTable,
    BaseCheckbox,
    BaseTextArea,
  },
  props: {
    good: {
      type: Object,
    },
  },
  data() {
    return {
      contractReceived: {
        accessories: [],
      },
      addressFields: [
        {
          field: 'address',
          label: this.$t('customers.address'),
        },
        {
          field: 'postcode',
          label: this.$t('customers.postcode'),
        },
        {
          field: 'city',
          label: this.$t('customers.city'),
        },
        {
          field: 'co',
          label: this.$t('customers.co'),
        },
        {
          field: 'state',
          label: this.$t('customers.state'),
        },
        {
          field: 'country',
          label: this.$t('customers.country'),
        },
      ],
      shippingAddressFields: [
        {
          field: 'shipping_company',
          label: this.$t('customers.fullname'),
        },
        {
          field: 'shipping_address',
          label: this.$t('customers.address'),
        },
        {
          field: 'shipping_postcode',
          label: this.$t('customers.postcode'),
        },
        {
          field: 'shipping_city',
          label: this.$t('customers.city'),
        },
        {
          field: 'shipping_co',
          label: this.$t('customers.co'),
        },
        {
          field: 'shipping_state',
          label: this.$t('customers.state'),
        },
        {
          field: 'shipping_country',
          label: this.$t('customers.country'),
        },
      ],
      workflowStates: {
        draft: this.$t('contracts.workflowStates.draft'),
        agreement_created_waiting_to_be_signed: this.$t('contracts.workflowStates.agreement_created_waiting_to_be_signed'),
        agreement_email_viewed: this.$t('contracts.workflowStates.agreement_email_viewed'),
        agreement_signed: this.$t('contracts.workflowStates.agreement_signed'),
        ready_to_be_shipped: this.$t('contracts.workflowStates.ready_to_be_shipped'),
        shipped: this.$t('contracts.workflowStates.shipped'),
        buy_good_requested: this.$t('contracts.workflowStates.buyGoodRequested'),
        stop_rent_requested: this.$t('contracts.workflowStates.stopRentRequested'),
        waiting_for_return: this.$t('contracts.workflowStates.waiting_for_return'),
        good_returned: this.$t('contracts.workflowStates.good_returned'),
        returned_good_check: this.$t('contracts.workflowStates.returned_good_check'),
        closed: this.$t('contracts.workflowStates.closed'),
        cancelled: this.$t('contracts.workflowStates.cancelled'),
      },
      selectedEmail: '',
      emailTypeList: [
        { id: 'optin', description: this.$t('contracts.emailTypes.optin') },
        { id: 'collect_payment', description: this.$t('contracts.emailTypes.collectPayment') },
        { id: 'stop_rent', description: this.$t('contracts.emailTypes.stopRent') },
        { id: 'good_shipped', description: this.$t('contracts.emailTypes.goodShipped') },
        { id: 'send_customer_password', description: this.$t('contracts.emailTypes.dashboardInfo') },
      ],
      selectedGoodAction: '',
      goodActionList: [
        { id: 'sold', description: this.$t('contracts.goodActionList.sold') },
        { id: 'resume', description: this.$t('contracts.goodActionList.resume') },
      ],
      contractInvoices: [],
      isNotesDisabled: true,
      saveButtonDisabled: true,
    };
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  async created() {
    await this.getContracts();
    await this.initData();
  },
  computed: {
    ...mapGetters('auth', ['isSuperAdmin']),
    ...mapGetters('contracts', ['contracts', 'contract']),
    ...mapGetters('customers', ['customers', 'shippingAddress']),
    isNotPerson() {
      const [customer] = this.customers.filter((item) => item.id === this.contractReceived.customer_id);
      return customer && customer.type === 'G';
    },
    isDeleteDisable() {
      return this.contractReceived.workflow_status !== 'draft';
    },
    isCancelDisable() {
      return this.contractReceived.workflow_status !== 'draft'
      && this.contractReceived.workflow_status !== 'agreement_created_waiting_to_be_signed'
      && this.contractReceived.workflow_status !== 'agreement_email_viewed';
    },
    workflowState() {
      return this.workflowStates[this.contractReceived.workflow_status];
    },
    contractIsActive() {
      const isActiveValue = {
        true: this.$t('yes'),
        false: this.$t('no'),
      };
      return isActiveValue[this.contractReceived.is_active];
    },
    feeBasisTranslation() {
      const feeBasisTranslations = {
        daily: this.$t('contract_templates.daily'),
        monthly: this.$t('contract_templates.monthly'),
        quarterly: this.$t('contract_templates.quarterly'),
      };
      return feeBasisTranslations[this.contractReceived.fee_basis];
    },
    contractAccessories() {
      return this.contractReceived.accessories.map((accessory) => accessory.description).join(', ');
    },
    invoicesLength() {
      return this.contractInvoices.flatMap((contract) => contract.invoices).length;
    },
    showNotesField() {
      return this.contractReceived.notes ? true : !this.isNotesDisabled;
    },
  },
  methods: {
    ...mapActions('goods', ['setGood']),
    ...mapActions('contractTemplates', ['setContractTemplate']),
    ...mapActions('customers', ['setCustomer', 'setShippingAddress', 'goodSold', 'resumeRent', 'putCustomer']),
    ...mapActions('contracts', ['getContracts', 'setContract', 'putContract', 'resendEmails', 'getInvoices']),
    async initData() {
      if (this.good && Object.keys(this.good).length > 0) {
        [this.contractReceived] = this.contracts
          .filter((contract) => contract.is_active === true)
          .filter((contract) => contract.good && contract.good.id === this.good.id);
        return;
      }
      this.contractReceived = { ...this.contract };
      const invoices = await this.getInvoices(this.contractReceived.id);
      this.contractInvoices = [{ contractId: this.contractReceived.id, ...invoices }];
    },
    async updateContract() {
      const {
        id,
        workflow_status,
        is_active,
        notes,
      } = this.contractReceived;

      const updatedContract = await this.putContract({
        id,
        payload: {
          workflow_status,
          is_active,
          notes,
        },
      });

      await this.setContract(updatedContract);
      if (this.contractReceived.workflow_status === 'closed') {
        this.toast.success(this.$t('contracts.was_closed'));
      }
      if (this.contractReceived.workflow_status === 'cancelled') {
        this.toast.success(this.$t('contracts.was_cancelled'));
      }

      this.setContractTemplate({});
      this.setCustomer({});
      this.setGood({});
      this.setShippingAddress({});
      this.$emit('close');
    },
    closedStatus() {
      this.contractReceived.workflow_status = 'closed';
      this.contractReceived.is_active = false;
    },
    cancelledStatus() {
      this.isNotesDisabled = false;
      this.saveButtonDisabled = false;
      this.contractReceived.workflow_status = 'cancelled';
      this.contractReceived.is_active = false;
    },
    async sendEmail() {
      await this.resendEmails({ contractId: this.contractReceived.id, mailable: this.selectedEmail });
      this.toast.success(this.$t('contracts.emailRequestSent'));
    },
    async executeAction() {
      const actions = {
        sold: 'goodSold',
        resume: 'resumeRent',
      };
      await this[actions[this.selectedGoodAction]]({ customerId: this.contractReceived.customer_id, contractId: this.contractReceived.id });
      this.contractReceived.workflow_status = actions[this.selectedGoodAction] === 'goodSold'
        ? 'closed' : 'shipped';
      this.contractReceived.is_active = !(actions[this.selectedGoodAction] === 'goodSold');
      await this.updateContract();
      this.toast.info(this.$t('contracts.actionExecuted'));
    },
    reserveFunds() {
      axios.get(`/api/workflow/force-reservation/${this.contract.customer_id}`).then(() => {
        this.toast.success(this.$t('contracts.reserveFundsSuccess'));
      }).catch(() => {
        this.toast.error(this.$t('customers.toast_error'));
      });
    },
    async removePaymentMethod() {
      try {
        await this.putCustomer({ id: this.contract.customer_id, payload: { pm_type: null, pm_last_four: null } });
        this.toast.success(this.$t('contracts.removePaymentMethodSuccess'));
      } catch {
        this.toast.error(this.$t('customers.toast_error'));
      }
    },
  },
};
</script>

<style scoped>
.responsive-icon{
  width: min(max(1em, 1.5vw), 2em);
  height: min(max(1em, 1.5vw), 2em);
}
</style>
